import { ChatGroup, ChatMessage, Conversation, SearchMessage, SendMessage } from 'types/Chat';
import { ApiResponse, BaseSearch } from 'types/common';
import http from './http';

const getConversation = async (staffId: number, customerId: number) => {
    const { data } = await http.post<ApiResponse<ChatGroup>>('/chat/group', { staffId, customerId });
    return data;
};

const getConversations = async (userId: number, param: BaseSearch) => {
    const { data } = await http.post<ApiResponse<Conversation[]>>(`/chat/conversation/${userId}`, param);
    return data;
};

const getTotalUnread = async (userId: number) => {
    const { data } = await http.get<ApiResponse<number>>(`/chat/totalUnread/${userId}`);
    return data;
};

const readMessageByConversation = async (conversationId: string, userId: number) => {
    const { data } = await http.get<ApiResponse<{}>>(`/chat/readMessages/${conversationId}/${userId}`);
    return data;
};

const readMessageByIds = async (userId: number, messageIds: string[]) => {
    const { data } = await http.post<ApiResponse<{}>>(`/chat/readMessage/${userId}`, { messageIds });
    return data;
};

const getMessages = async (param: SearchMessage) => {
    const { data } = await http.post<ApiResponse<ChatMessage[]>>('/chat/message', param);
    return data;
};

const sendMessage = async (param: SendMessage) => {
    const { data } = await http.post<ApiResponse<string>>('/chat/sendMessage', param);
    return data;
};

const ChatService = {
    getConversation,
    getConversations,
    getTotalUnread,
    readMessageByConversation,
    readMessageByIds,
    getMessages,
    sendMessage,
};

export default ChatService;
