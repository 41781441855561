import { DEFAULT_VALUE } from 'constants/common';
import { Menu, Users } from 'react-feather';
import { ChatGroup } from 'types/Chat';
import User from 'types/User';
import { getFieldInArrayObject } from 'utils/common';

interface IProps {
    isCustomer: boolean;
    chatGroup: ChatGroup | undefined;
    users: User[];
}

HeaderChat.defaultProps = {
    isCustomer: false,
    chatGroup: undefined,
    users: [],
};
export default function HeaderChat({ isCustomer, chatGroup, users }: IProps) {
    return (
        <div className="chat-navbar">
            <header className="chat-header">
                <div className="d-flex align-items-center">
                    <div className="sidebar-toggle d-block d-lg-none me-1">
                        <Menu size={14} />
                    </div>
                    <div className="avatar avatar-border user-profile-toggle m-0 me-1">
                        <img
                            src={
                                chatGroup
                                    ? getFieldInArrayObject(
                                          users,
                                          isCustomer ? chatGroup.staffId : chatGroup.customerId,
                                          'avatar',
                                          DEFAULT_VALUE.IMAGE
                                      )
                                    : DEFAULT_VALUE.IMAGE
                            }
                            alt="avatar"
                            height={36}
                            width={36}
                        />
                        {/* <span
                            className={`avatar-status-${conversation && conversation.isOnline ? 'online' : 'offline'}`}
                        /> */}
                    </div>
                    <h6 className="mb-0">
                        {chatGroup
                            ? getFieldInArrayObject(users, isCustomer ? chatGroup.staffId : chatGroup.customerId)
                            : ''}{' '}
                        <Users size={14} />
                    </h6>
                </div>
            </header>
        </div>
    );
}
