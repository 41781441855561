import { UserRole } from 'types/User';
import { ItemParam, ItemStatus, TrueFalse } from 'types/common/Item';

export enum ConsultStatus {
    NEW_CUSTOMER = 1, // khách mới (là khách chưa có đơn hàng)
    PROMISED_CUSTOMER = 2, // khách hứa (khách báo là đang tham khảo và chưa có đơn hàng)
    POTENTIAL_CUSTOMER = 3, // khách tiềm năng (khách đã có đơn hàng rồi)
    CUSTOMER_VIP = 4, // khách VIP (là khách được tích ưu đãi vip)
    CUSTOMER_NOT_PLACE_ORDER = 5, // khách quên (khách đã có đơn hàng r nhưng trong 2 tuần không lên đơn)
    OLD_CUSTOMER = 6, // khách cũ (khách 1 tháng không có đơn hàng)
    CUSTOMER_CANCELS = 7, // khách hủy (khách không có nhu cầu đặt hàng)
}

export const ConsultStatusNames: ItemParam[] = [
    { id: ConsultStatus.NEW_CUSTOMER, name: 'Khách mới' },
    { id: ConsultStatus.PROMISED_CUSTOMER, name: 'Khách hứa' },
    { id: ConsultStatus.POTENTIAL_CUSTOMER, name: 'Khách tiềm năng' },
    { id: ConsultStatus.CUSTOMER_VIP, name: 'Khách VIP' },
    { id: ConsultStatus.CUSTOMER_NOT_PLACE_ORDER, name: 'Khách quên' },
    { id: ConsultStatus.OLD_CUSTOMER, name: 'Khách cũ' },
    { id: ConsultStatus.CUSTOMER_CANCELS, name: 'Khách huỷ' },
];

export const UserStatusNames: ItemParam[] = [
    { id: ItemStatus.INACTIVE, name: 'Không được duyệt', className: 'badge badge-glow bg-danger' },
    { id: ItemStatus.ACTIVE, name: 'Đã duyệt', className: 'badge badge-glow bg-success' },
    { id: ItemStatus.WAIT, name: 'Chờ duyệt', className: 'badge badge-glow bg-warning' },
    { id: ItemStatus.CANCEL, name: 'Nghỉ việc', className: 'badge badge-glow bg-dark' },
];

export const UserRoleNames: ItemParam[] = [
    { id: UserRole.CUSTOMER_CARE_STAFF, name: 'Nhân viên CSKH', className: 'badge badge-glow bg-primary' },
    { id: UserRole.DELIVERY_STAFF, name: 'Nhân viên Vận chuyển', className: 'badge badge-glow bg-warning' },
    { id: UserRole.GENERAL_MANAGER, name: 'Quản lý chung', className: 'badge badge-glow bg-success' },
    //{ id: UserRole.CUSTOMER, name: 'Khách hàng', className: 'badge badge-glow bg-danger' },
    { id: UserRole.ORDER_STAFF, name: 'Nhân viên Đặt hàng', className: 'badge badge-glow bg-info' },
    { id: UserRole.ACCOUNTING, name: 'Kế toán', className: 'badge badge-glow bg-secondary' },
    { id: UserRole.CUSTOMER_CARE_MANAGER, name: 'Quản lý CSKH', className: 'badge badge-glow bg-primary' },
    { id: UserRole.ORDER_MANAGER, name: 'Quản lý Đặt hàng', className: 'badge badge-glow bg-info' },
    { id: UserRole.DELIVERY_MANAGER, name: 'Quản lý Vận chuyển', className: 'badge badge-glow bg-warning' },
    { id: UserRole.MARKETING_STAFF, name: 'Nhân viên Marketing', className: 'badge badge-glow bg-success' },
];

export const IsVipNames: ItemParam[] = [
    { id: TrueFalse.FALSE, name: 'Khách hàng thường' },
    { id: TrueFalse.TRUE, name: 'Khách hàng VIP' },
];

export const IsPositiveBalanceNames: ItemParam[] = [
    { id: TrueFalse.FALSE, name: 'Dư nợ âm' },
    { id: TrueFalse.TRUE, name: 'Dư nợ dương' },
];

export const CustomerTabNames: ItemParam[] = [
    { id: 1, name: 'Thông tin cơ bản', className: 'thong-tin' },
    { id: 2, name: 'Bảo mật', className: 'bao-mat' },
    { id: 3, name: 'Đơn hàng', className: 'don-hang' },
    { id: 4, name: 'Tài chính', className: 'tai-chinh' },
    { id: 5, name: 'Phí dịch vụ', className: 'phi-dich-vu' },
    { id: 6, name: 'Phí vận chuyển', className: 'phi-van-chuyen' },
    { id: 7, name: 'Báo khách', className: 'bao-khach' },
];
