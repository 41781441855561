import Notification, { SearchNotification } from 'types/Notification';
import { ItemCount } from 'types/common/Item';
import { ApiResponse, DataListResponse } from '../types/common';
import http from './http';

const getList = async (param: SearchNotification) => {
    const { data } = await http.post<ApiResponse<DataListResponse<Notification>>>('/notification/list', param);
    return data;
};

const getCounts = async () => {
    const { data } = await http.get<ApiResponse<ItemCount[]>>('/notification/counts');
    return data;
};

const seen = async (id: number = 0) => {
    const { data } = await http.get<ApiResponse<{}>>(`/notification/seen/${id}`);
    return data;
};

const NotificationService = {
    getList,
    getCounts,
    seen,
};

export default NotificationService;
