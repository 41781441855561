import classNames from 'classnames';
import HeaderChat from 'components/partials/chat/HeaderChat';
import ListChatMessage from 'components/partials/chat/ListChatMessage';
import NoConversation from 'components/partials/chat/NoConversation';
import { ChatGroup } from 'types/Chat';
import User from 'types/User';

interface IProps {
    isCustomer: boolean;
    profileId: number;
    chatGroup: ChatGroup | undefined;
    users: User[];
    isFullWidth: boolean;
    isShowInput: boolean;
}

export default function ContentChatMessage({
    isCustomer,
    profileId,
    chatGroup,
    users,
    isFullWidth,
    isShowInput,
}: Readonly<IProps>) {
    return (
        <div
            className={classNames('content-right', {
                'w-100': isFullWidth,
            })}
        >
            <div className="content-wrapper container-xxl p-0">
                <div className="content-header row" />
                <div className="content-body">
                    <div className="body-content-overlay" />
                    <section className="chat-app-window">
                        <NoConversation isHide={!!chatGroup} message="Hãy chọn một cuộc hội thoại" />
                        <div className="active-chat">
                            <HeaderChat isCustomer={isCustomer} chatGroup={chatGroup} users={users} />
                            <ListChatMessage
                                chatGroup={chatGroup}
                                profileId={profileId}
                                isShowInput={isShowInput}
                                users={users}
                            />
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
}
