import { useAppDispatch, useAppSelector } from 'app/hooks';
import BoxErrorMessage from 'components/partials/BoxErrorMessage';
import Footer from 'components/partials/Footer';
import Header from 'components/partials/Header';
import { AUTH_KEYS } from 'constants/auth';
import { MenuCustomers } from 'constants/common';
import { isEmpty, replace, startsWith } from 'lodash';
import { useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import UserService from 'services/UserService';
import { getActions, selectUser, setUser } from 'slices/authSlice';
import Action from 'types/Action';
import { TrueFalse } from 'types/common/Item';
import { getLocalStorage } from 'utils/localStorage';

export function PrivateRoute({ children }: { children: JSX.Element }) {
    const location = useLocation();
    const dispatch = useAppDispatch();
    const [accessToken, setAccessToken] = useState(getLocalStorage(AUTH_KEYS.ACCESS_TOKEN));
    const user = useAppSelector(selectUser);
    const [listActions, setListActions] = useState<Action[]>([]);
    const [actionLoaded, setActionLoaded] = useState(false);
    const [canAccess, setCanAccess] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const getUser = async () => {
            if (!user && accessToken) {
                const results = await UserService.getProfile();
                if (results.success) {
                    dispatch(setUser(results.data));
                } else {
                    setAccessToken('');
                }
            }
        };
        const getListActions = async () => {
            if (user && isEmpty(listActions)) {
                if (user.isCustomer === TrueFalse.TRUE) {
                    setListActions(MenuCustomers);
                    setActionLoaded(true);
                    setCanAccess(true);
                } else {
                    const actionResult = await dispatch(getActions(user.roleId));
                    if (getActions.fulfilled.match(actionResult)) {
                        if (actionResult.payload.data) {
                            setListActions(actionResult.payload.data.items);
                            setActionLoaded(true);
                            //TODO: VNL
                            setCanAccess(true);
                        }
                    }
                }
            }
        };
        const redirectCustomerUrl = () => {
            if (user) {
                if (user.isCustomer === TrueFalse.TRUE) {
                    if (startsWith(location.pathname, '/order/')) {
                        navigate(`/customer${location.pathname}`);
                    } else if (!startsWith(location.pathname, '/customer/')) {
                        navigate('/customer/profile');
                    }
                } else if (startsWith(location.pathname, '/customer/')) {
                    if (startsWith(location.pathname, '/customer/order')) {
                        navigate(replace(location.pathname, '/customer', ''));
                    } else navigate('/dashboard');
                }
            }
        };
        getUser();
        getListActions();
        redirectCustomerUrl();
    }, [user, location, listActions]);

    if (!isEmpty(accessToken)) {
        return (
            <>
                <Header listActions={listActions} user={user} />
                <div className="app-content content ">
                    <div className="content-overlay" />
                    <div className="header-navbar-shadow" />
                    {actionLoaded && canAccess && <div className="content-wrapper container-xxl p-0">{children}</div>}
                    {actionLoaded && !canAccess && (
                        <div className="content-wrapper container-xxl p-0">
                            <div className="content-body">
                                <BoxErrorMessage
                                    messageNotFound={['Tài nguyên được yêu cầu không được tìm thấy trên máy chủ']}
                                />
                            </div>
                        </div>
                    )}
                </div>
                <Footer />
            </>
        );
    }
    return <Navigate to={`/?url=${location.pathname}`} />;
}
