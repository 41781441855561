import { isEmpty } from 'lodash';

interface IProps {
    messageNotFound: string[];
}

BoxErrorMessage.defaultProps = {
    messageNotFound: [],
};

export default function BoxErrorMessage({ messageNotFound }: IProps) {
    return !isEmpty(messageNotFound) ? (
        <div className="alert alert-danger" role="alert">
            <h4 className="alert-heading">Thông báo</h4>
            <div className="alert-body">
                {messageNotFound.map((message: string, index: number) => (
                    <p key={index}>{message}</p>
                ))}
            </div>
        </div>
    ) : (
        <></>
    );
}
