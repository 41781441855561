import { NotFound, PrivateRoute } from 'components/commons';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

const AuthPage = React.lazy(() => import('features/auth/pages/AuthPage'));
const LoginPage = React.lazy(() => import('features/auth/pages/Login'));
const ForgotPage = React.lazy(() => import('features/auth/pages/ForgotPass'));
const ChangePassPage = React.lazy(() => import('features/auth/pages/ChangePass'));
const SetToken = React.lazy(() => import('features/auth/pages/SetToken'));

const CustomerProfile = React.lazy(() => import('features/customer/pages/CustomerProfile'));
const CustomerOrderList = React.lazy(() => import('features/customer/pages/CustomerOrderList'));
//const CustomerDraffOrderList = React.lazy(() => import('features/customer/pages/CustomerDraffOrderList'));
const CustomerOrderAdd = React.lazy(() => import('features/customer/pages/CustomerOrderAdd'));
const CustomerOrderEdit = React.lazy(() => import('features/customer/pages/CustomerOrderEdit'));
const CustomerWithdrawal = React.lazy(() => import('features/customer/pages/CustomerWithdrawal'));
const CustomerWishlist = React.lazy(() => import('features/customer/pages/CustomerWishlist'));
const CustomerTransactionList = React.lazy(() => import('features/customer/pages/CustomerTransactionList'));
const CustomerTransportList = React.lazy(() => import('features/customer/pages/CustomerTransportList'));
const ChatPage = React.lazy(() => import('components/partials/chat/ChatPage'));
const ComplaintList = React.lazy(() => import('components/partials/complaints/ComplaintList'));
const CustomerShopProductList = React.lazy(() => import('features/customer/pages/CustomerShopProductList'));
const ShopTrackingList = React.lazy(() => import('components/partials/shops/ShopTrackingList'));
const NotificationList = React.lazy(() => import('components/partials/notifications/NotificationList'));
const UserDashboard = React.lazy(() => import('features/user/pages/UserDashboard'));
const ActionList = React.lazy(() => import('features/action/pages/ActionList'));
const RoleAction = React.lazy(() => import('features/action/pages/RoleAction'));
const ItemParamList = React.lazy(() => import('features/config/pages/ItemParamList'));
const ConfigList = React.lazy(() => import('features/config/pages/ConfigList'));
const ServicePercentList = React.lazy(() => import('features/config/pages/ServicePercentList'));
const WeightCostList = React.lazy(() => import('features/config/pages/WeightCostList'));
const RevenueMilestoneList = React.lazy(() => import('features/config/pages/RevenueMilestoneList'));
const DocumentList = React.lazy(() => import('features/config/pages/DocumentList'));
const MoneySourceList = React.lazy(() => import('features/money/pages/MoneySourceList'));
const CustomerList = React.lazy(() => import('features/user/pages/CustomerList'));
const CustomerEdit = React.lazy(() => import('features/user/pages/CustomerEdit'));
const CustomerConsult = React.lazy(() => import('features/user/pages/CustomerConsult'));
const PromotionList = React.lazy(() => import('features/promotion/pages/PromotionList'));
const PromotionAdd = React.lazy(() => import('features/promotion/pages/PromotionAdd'));
const PromotionEdit = React.lazy(() => import('features/promotion/pages/PromotionEdit'));
const UserList = React.lazy(() => import('features/user/pages/UserList'));
const UserAdd = React.lazy(() => import('features/user/pages/UserAdd'));
const UserEdit = React.lazy(() => import('features/user/pages/UserEdit'));
const ArticlePostList = React.lazy(() => import('features/article/pages/ArticlePostList'));
const ArticleAdd = React.lazy(() => import('features/article/pages/ArticleAdd'));
const ArticleEdit = React.lazy(() => import('features/article/pages/ArticleEdit'));
const ArticleNotificationList = React.lazy(() => import('features/article/pages/ArticleNotificationList'));
const ArticleFeedbackList = React.lazy(() => import('features/article/pages/ArticleFeedbackList'));
const ArticleLinkList = React.lazy(() => import('features/article/pages/ArticleLinkList'));
const ArticleItemList = React.lazy(() => import('features/article/pages/ArticleItemList'));

const OrderList = React.lazy(() => import('features/order/pages/OrderList'));
const DraffOrderList = React.lazy(() => import('features/order/pages/DraffOrderList'));
const OrderAdd = React.lazy(() => import('features/order/pages/OrderAdd'));
const OrderEdit = React.lazy(() => import('features/order/pages/OrderEdit'));
const AddOrderUser = React.lazy(() => import('features/order/pages/AddOrderUser'));
const OrderPendingList = React.lazy(() => import('features/order/pages/OrderPendingList'));
const WithdrawalList = React.lazy(() => import('features/withdrawal/pages/WithdrawalList'));
const TransactionList = React.lazy(() => import('features/transaction/pages/TransactionList'));
const TransportList = React.lazy(() => import('features/transport/pages/TransportList'));
const TransportPrint = React.lazy(() => import('features/transport/pages/TransportPrint'));
const TransportTypeList = React.lazy(() => import('features/transport/pages/TransportTypeList'));
const ShopReturnList = React.lazy(() => import('features/shop/pages/ShopReturnList'));
const ShopNumberList = React.lazy(() => import('features/shop/pages/ShopNumberList'));
const ShopTrackingLateList = React.lazy(() => import('features/shop/pages/ShopTrackingLateList'));
const ShopProductList = React.lazy(() => import('features/shop/pages/ShopProductList'));
const RechargeList = React.lazy(() => import('features/recharge/pages/RechargeList'));

export default function RouterView() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<AuthPage />}>
                    <Route index element={<LoginPage />} />
                    <Route path="forgot" element={<ForgotPage />} />
                    <Route path="change-password" element={<ChangePassPage />} />
                    <Route path="set-token" element={<SetToken />} />
                </Route>
                <Route path="/admin" element={<AuthPage />}>
                    <Route index element={<LoginPage />} />
                    <Route path="forgot" element={<ForgotPage />} />
                    <Route path="change-password" element={<ChangePassPage />} />
                </Route>
                <Route
                    path="/customer/profile"
                    element={
                        <PrivateRoute>
                            <CustomerProfile />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/customer/order"
                    element={
                        <PrivateRoute>
                            <CustomerOrderList />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/customer/draffOrder"
                    element={
                        <PrivateRoute>
                            {/* <CustomerDraffOrderList /> */}
                            <CustomerOrderAdd />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/customer/addOrder"
                    element={
                        <PrivateRoute>
                            <CustomerOrderAdd />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/customer/order/edit/:id"
                    element={
                        <PrivateRoute>
                            <CustomerOrderEdit />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/customer/withdrawal"
                    element={
                        <PrivateRoute>
                            <CustomerWithdrawal />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/customer/wishlist"
                    element={
                        <PrivateRoute>
                            <CustomerWishlist />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/customer/transaction"
                    element={
                        <PrivateRoute>
                            <CustomerTransactionList />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/customer/transport"
                    element={
                        <PrivateRoute>
                            <CustomerTransportList />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/customer/tracking"
                    element={
                        <PrivateRoute>
                            <ShopTrackingList isCustomer={true} />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/customer/complaint"
                    element={
                        <PrivateRoute>
                            <ComplaintList isCustomer={true} />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/customer/product"
                    element={
                        <PrivateRoute>
                            <CustomerShopProductList />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/customer/notification"
                    element={
                        <PrivateRoute>
                            <NotificationList isCustomer={true} />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/customer/chat"
                    element={
                        <PrivateRoute>
                            <ChatPage isCustomer={true} />
                        </PrivateRoute>
                    }
                ></Route>

                <Route
                    path="/dashboard"
                    element={
                        <PrivateRoute>
                            <UserDashboard />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/action"
                    element={
                        <PrivateRoute>
                            <ActionList />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/permission"
                    element={
                        <PrivateRoute>
                            <RoleAction />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/item/:type"
                    element={
                        <PrivateRoute>
                            <ItemParamList />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/moneySource"
                    element={
                        <PrivateRoute>
                            <MoneySourceList />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/config"
                    element={
                        <PrivateRoute>
                            <ConfigList />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/servicePercent"
                    element={
                        <PrivateRoute>
                            <ServicePercentList />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/weightCost"
                    element={
                        <PrivateRoute>
                            <WeightCostList />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/revenueMilestone"
                    element={
                        <PrivateRoute>
                            <RevenueMilestoneList />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/promotion"
                    element={
                        <PrivateRoute>
                            <PromotionList />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/promotion/add"
                    element={
                        <PrivateRoute>
                            <PromotionAdd />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/promotion/edit/:id"
                    element={
                        <PrivateRoute>
                            <PromotionEdit />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/userCustomer"
                    element={
                        <PrivateRoute>
                            <CustomerList />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/userCustomer/edit/:id"
                    element={
                        <PrivateRoute>
                            <CustomerEdit />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/consult"
                    element={
                        <PrivateRoute>
                            <CustomerConsult />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/user"
                    element={
                        <PrivateRoute>
                            <UserList />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/user/add/:type?"
                    element={
                        <PrivateRoute>
                            <UserAdd />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/user/edit/:id"
                    element={
                        <PrivateRoute>
                            <UserEdit />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/article/post"
                    element={
                        <PrivateRoute>
                            <ArticlePostList />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/article/add"
                    element={
                        <PrivateRoute>
                            <ArticleAdd />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/article/edit/:id"
                    element={
                        <PrivateRoute>
                            <ArticleEdit />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/article/notification"
                    element={
                        <PrivateRoute>
                            <ArticleNotificationList />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/article/feedback"
                    element={
                        <PrivateRoute>
                            <ArticleFeedbackList />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/article/video"
                    element={
                        <PrivateRoute>
                            <ArticleLinkList type="video" />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/article/document"
                    element={
                        <PrivateRoute>
                            <ArticleLinkList type="document" />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/article/item/:type"
                    element={
                        <PrivateRoute>
                            <ArticleItemList />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/document"
                    element={
                        <PrivateRoute>
                            <DocumentList />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/order"
                    element={
                        <PrivateRoute>
                            <OrderList />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/draffOrder"
                    element={
                        <PrivateRoute>
                            <DraffOrderList />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/order/add"
                    element={
                        <PrivateRoute>
                            <OrderAdd />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/order/edit/:id"
                    element={
                        <PrivateRoute>
                            <OrderEdit />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/order/pending"
                    element={
                        <PrivateRoute>
                            <OrderPendingList />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/addOrderUser"
                    element={
                        <PrivateRoute>
                            <AddOrderUser />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/withdrawal"
                    element={
                        <PrivateRoute>
                            <WithdrawalList />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/transaction/:type"
                    element={
                        <PrivateRoute>
                            <TransactionList />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/transport"
                    element={
                        <PrivateRoute>
                            <TransportList />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/transport/print/:id/:type"
                    element={
                        <PrivateRoute>
                            <TransportPrint />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/transportType"
                    element={
                        <PrivateRoute>
                            <TransportTypeList />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/complaint"
                    element={
                        <PrivateRoute>
                            <ComplaintList isCustomer={false} />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/shop/return"
                    element={
                        <PrivateRoute>
                            <ShopReturnList />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/shop/number"
                    element={
                        <PrivateRoute>
                            <ShopNumberList />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/shop/tracking"
                    element={
                        <PrivateRoute>
                            <ShopTrackingList isCustomer={false} />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/shop/lateTracking"
                    element={
                        <PrivateRoute>
                            <ShopTrackingLateList />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/shop/product"
                    element={
                        <PrivateRoute>
                            <ShopProductList />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/recharge"
                    element={
                        <PrivateRoute>
                            <RechargeList />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/notification"
                    element={
                        <PrivateRoute>
                            <NotificationList isCustomer={false} />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/chat"
                    element={
                        <PrivateRoute>
                            <ChatPage isCustomer={false} />
                        </PrivateRoute>
                    }
                ></Route>
                <Route path="not-found" element={<NotFound />}></Route>
                <Route path="*" element={<NotFound />}></Route>
            </Routes>
        </BrowserRouter>
    );
}
