import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { COMMON_MESSAGE } from 'constants/common';
import ActionService from 'services/ActionService';
import UserService from 'services/UserService';
import Action from 'types/Action';
import User, { LoginData } from 'types/User';
import { HierarchyReturnType } from 'types/common/Item';
export interface AuthState {
    user: User | null;
    actions: Action[];
    isLoading: boolean;
    messages: string[];
}

const initialState: AuthState = {
    user: null,
    actions: [],
    isLoading: false,
    messages: [],
};

export const login = createAsyncThunk('user/login', async (data: LoginData) => UserService.login(data));

export const logout = createAsyncThunk('user/logout', async () => UserService.logout());

export const getActions = createAsyncThunk('action/getListByRole', async (roleId: number) =>
    ActionService.getList(HierarchyReturnType.PARENT, roleId)
);

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setUser: (state, action) => {
            state.user = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(login.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(login.fulfilled, (state, action) => {
                state.isLoading = false;
                const apiResponse = action.payload;
                if (apiResponse.success) {
                    const u = apiResponse.data;
                    state.user = u;
                } else state.messages = apiResponse.messages;
            })
            .addCase(getActions.fulfilled, (state, action) => {
                const apiResponse = action.payload;
                if (apiResponse.success) {
                    state.actions = apiResponse.data.items;
                }
            })
            .addCase(login.rejected, (state) => {
                state.isLoading = false;
                state.messages = [COMMON_MESSAGE.ERROR_MESSAGE as string];
            })
            .addCase(logout.fulfilled, (state) => {
                state.isLoading = false;
                state.user = null;
                state.actions = [];
                state.messages = [];
            });
    },
});

export const { setUser } = authSlice.actions;

export const selectUser = (state: RootState) => state.auth.user;

export const selectActions = (state: RootState) => state.auth.actions;

export default authSlice.reducer;
