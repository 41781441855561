import classNames from 'classnames';
import { MessageSquare } from 'react-feather';

interface IProps {
    isHide: boolean;
    message: string;
}

NoConversation.defaultProps = {
    isHide: true,
    message: '',
};

export default function NoConversation({ isHide }: IProps) {
    return (
        <div className={classNames('start-chat-area', { 'd-none': isHide })}>
            <div className="mb-1 start-chat-icon">
                <MessageSquare size={14} />
            </div>
            <h4 className="sidebar-toggle start-chat-text">Chưa có cuộc hội thoại</h4>
        </div>
    );
}
