import classNames from 'classnames';
import { AUTH_KEYS } from 'constants/auth';
import { DEFAULT_VALUE, REACT_APP_CHAT_URL } from 'constants/common';
import { isEmpty, remove, reverse } from 'lodash';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Paperclip, Plus, Send, XCircle } from 'react-feather';
import { useForm } from 'react-hook-form';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import ChatService from 'services/ChatService';
import FileService from 'services/FileService';
import { io } from 'socket.io-client';
import { ChatGroup, ChatMessage, SearchMessage } from 'types/Chat';
import User from 'types/User';
import { BaseSearch } from 'types/common';
import { FileParam, FileTypeParam } from 'types/common/Item';
import { getFieldInArrayObject, showToast } from 'utils/common';
import { FORMAT_DATE, formatCurrentDateTime, formatDateTime } from 'utils/date';
import { getLocalStorage, setLocalStorage } from 'utils/localStorage';
import ModalAddLinkChat from './ModalAddLinkChat';

interface IProps {
    chatGroup: ChatGroup | undefined;
    profileId: number;
    isShowInput: boolean;
    users: User[];
}

export default function ListChatMessage({ chatGroup, profileId, isShowInput, users }: Readonly<IProps>) {
    const [messages, setMessages] = useState<ChatMessage[]>([]);
    const [beforeTmpId, setBeforeTmpId] = useState('');
    const scrollRef = useRef<HTMLSpanElement>(null);
    const { register, handleSubmit, reset } = useForm<BaseSearch>();
    const [images, setImages] = useState<string[]>([]);
    const [file, setFile] = useState<FileParam>();
    const [isFocused, setIsFocused] = useState(false);
    const [linkOrder, setLinkOrder] = useState('');
    const [show, setShow] = useState(false);
    const [enterToSend, setEnterToSend] = useState(false);

    const usePrevious = (value: string | undefined) => {
        const ref = useRef('');
        useEffect(() => {
            if (value) ref.current = value;
        });
        return ref.current;
    };
    const chatId = usePrevious(chatGroup?.conversationId);

    useEffect(() => {
        setEnterToSend(getLocalStorage(AUTH_KEYS.ENTER_TO_CHAT) === '1');
    }, []);

    useEffect(() => {
        if (chatGroup?.conversationId) {
            const socket = io(REACT_APP_CHAT_URL);
            socket.on('connect', () => {
                if (chatId !== chatGroup.conversationId) socket.emit('leave-room', { roomId: chatId });
                socket.emit('join-room', { roomId: chatGroup.conversationId });
            });
            socket.on('message', (data) => {
                if (data && +data.userId !== profileId) {
                    const newMessages = {
                        _id: data._id,
                        read: [data.userId],
                        text: data.text,
                        userId: data.userId,
                        images: data.images,
                        roomId: data.roomId,
                        file: data.file,
                        createdAt: formatCurrentDateTime(FORMAT_DATE.DB_DATE_TIME),
                    };
                    setMessages((oldMsgs) => [...oldMsgs, newMessages]);
                    reset({ searchText: '' });
                    setImages([]);
                }
            });
            return () => {
                socket.disconnect();
            };
        }
    }, [chatGroup]);

    useEffect(() => {
        if (chatGroup) {
            setMessages([]);
            reset({ searchText: '' });
            setImages([]);
            getMessages();
        }
    }, [chatGroup]);

    useEffect(() => {
        if (messages) readMessage();
    }, [messages]);

    useLayoutEffect(() => {
        if (scrollRef?.current) {
            scrollRef.current.scrollIntoView();
        }
    });

    const onSubmit = async (data: BaseSearch) => {
        data.searchText = data.searchText!.trim();
        if (chatGroup?.conversationId && (data.searchText || !isEmpty(images) || file)) {
            const response = await ChatService.sendMessage({
                conversationId: chatGroup?.conversationId,
                text: data.searchText,
                images: isEmpty(images) ? undefined : images,
                userId: profileId,
                file: file?.fileUrl,
            });
            if (response.success) {
                const newMessages = [...messages];
                newMessages.push({
                    _id: response.data,
                    read: [],
                    text: data.searchText,
                    userId: profileId.toString(),
                    images,
                    file: file?.fileUrl,
                    roomId: chatGroup?.conversationId,
                    createdAt: formatCurrentDateTime(FORMAT_DATE.DB_DATE_TIME),
                });
                setMessages(newMessages);
                reset({ searchText: '' });
                setImages([]);
                setFile(undefined);
            }
        }
    };

    const getMessages = async (isScroll = false, beforeId = '') => {
        if (chatGroup?.conversationId) {
            const param: SearchMessage = {
                conversationId: chatGroup?.conversationId,
                limit: 50,
                searchText: '',
            };
            if (isScroll) param.beforeId = beforeId;
            const response = await ChatService.getMessages(param);
            if (response.success) {
                if (!isEmpty(response.data)) {
                    const items = reverse(response.data);
                    if (!isEmpty(items)) setBeforeTmpId(items[0]._id);
                    if (isScroll) setMessages([...items, ...messages]);
                    else setMessages(items);
                } else setBeforeTmpId('');
            }
        }
    };

    const onYReachStart = () => {
        if (beforeTmpId !== '') {
            getMessages(true, beforeTmpId);
        }
    };

    const genMessage = (m: ChatMessage) => (
        <>
            {!isEmpty(m.images) && (
                <div className="demo-inline-spacing">
                    {m.images!.map((image: string, index: number) => (
                        <div className="avatar avatar-xl" key={index}>
                            <a href={image} target="_blank">
                                <img src={image} alt="avatar" />
                            </a>
                        </div>
                    ))}
                </div>
            )}
            {m.file && (
                <p>
                    <a href={m.file} target="_blank" className="text-file">
                        {m.file}
                    </a>
                </p>
            )}
            {m.location?.lat && m.location?.lng && (
                <p>
                    Vị trí: {m.location.lat} - {m.location.lng}
                </p>
            )}
            {m.text && <p className={classNames({ mt20: !isEmpty(m.images) })}>{m.text}</p>}
            {m.text && checkLinkURL(m.text) && (
                <p className="my-1 cursor-pointer" onClick={() => onOpenModal(m.text!)}>
                    <Plus size={14} strokeWidth={3} /> Thêm đơn hàng
                </p>
            )}
        </>
    );

    const checkLinkURL = (str: string) => {
        const pattern = new RegExp(
            '^(https?:\\/\\/)?' +
                '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
                '((\\d{1,3}\\.){3}\\d{1,3}))' +
                '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
                '(\\?[;&a-z\\d%_.~+=-]*)?' +
                '(\\#[-a-z\\d_]*)?$',
            'i'
        );
        return !!pattern.test(str);
    };

    const onOpenModal = (link: string) => {
        setLinkOrder(link);
        setShow(true);
    };

    const onChangeFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const results = await FileService.upload(event.target.files[0], FileTypeParam.CHAT);
            if (results.success) {
                if (
                    results.data.mimeType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                    results.data.mimeType === 'application/vnd.ms-excel'
                ) {
                    setFile(results.data);
                } else {
                    const tmp = [...images];
                    tmp.push(results.data.fileUrl);
                    setImages(tmp);
                }
            } else {
                showToast(false, results.messages);
            }
        }
    };

    const removeImg = (image: string) => {
        const listImg = remove(images, function (n) {
            return n !== image;
        });
        setImages(listImg);
    };

    const readMessage = async () => {
        const messageIds: string[] = [];
        messages.forEach((item) => {
            if (!item.read.includes(profileId.toString())) {
                messageIds.push(item._id);
            }
        });
        if (messageIds.length > 0) {
            const results = await ChatService.readMessageByIds(profileId, messageIds);
            if (!results.success) {
                showToast(false, results.messages);
            }
        }
    };

    const onChangeSend = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEnterToSend(e.target.checked);
        setLocalStorage(AUTH_KEYS.ENTER_TO_CHAT, e.target.checked ? '1' : '0');
    };

    const onEnter = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (event.which === 13 && !event.shiftKey && enterToSend) {
            handleSubmit(onSubmit)();
        }
    };

    const onSubmitAction = () => {};

    return (
        <>
            <div
                className={classNames('user-chats', {
                    short: isShowInput,
                })}
            >
                <PerfectScrollbar className="chats" onYReachStart={onYReachStart}>
                    {messages.map((m, index) => (
                        <div className={`chat ${+m.userId === profileId ? 'chat-right' : 'chat-left'}`} key={index}>
                            <div className="chat-avatar">
                                <span
                                    className="avatar box-shadow-1 cursor-pointer"
                                    title={getFieldInArrayObject(users, +m.userId)}
                                >
                                    <img
                                        src={getFieldInArrayObject(users, +m.userId, 'avatar', DEFAULT_VALUE.IMAGE)}
                                        alt="avatar"
                                        height={36}
                                        width={36}
                                    />
                                </span>
                            </div>
                            <div className="chat-body">
                                <div className="chat-content">
                                    <>
                                        {genMessage(m)}
                                        <small className="float-end mb-25 chat-time">
                                            {m.createdAt && formatDateTime(m.createdAt, FORMAT_DATE.SHOW_DATE_MINUTE)}
                                        </small>
                                    </>
                                </div>
                            </div>
                        </div>
                    ))}
                    <span ref={scrollRef} />
                </PerfectScrollbar>
                <div className="images-bar">
                    {images.map((image: string, index: number) => (
                        <div key={index}>
                            <XCircle size={20} color="Black" onClick={() => removeImg(image)} />
                            <img src={image} />
                        </div>
                    ))}
                    {file && (
                        <div title={file.fileName}>
                            <XCircle size={20} color="Black" onClick={() => setFile(undefined)} />
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="48px" height="48px">
                                <path fill="#169154" d="M29,6H15.744C14.781,6,14,6.781,14,7.744v7.259h15V6z" />
                                <path fill="#18482a" d="M14,33.054v7.202C14,41.219,14.781,42,15.743,42H29v-8.946H14z" />
                                <path fill="#0c8045" d="M14 15.003H29V24.005000000000003H14z" />
                                <path fill="#17472a" d="M14 24.005H29V33.055H14z" />
                                <g>
                                    <path fill="#29c27f" d="M42.256,6H29v9.003h15V7.744C44,6.781,43.219,6,42.256,6z" />
                                    <path
                                        fill="#27663f"
                                        d="M29,33.054V42h13.257C43.219,42,44,41.219,44,40.257v-7.202H29z"
                                    />
                                    <path fill="#19ac65" d="M29 15.003H44V24.005000000000003H29z" />
                                    <path fill="#129652" d="M29 24.005H44V33.055H29z" />
                                </g>
                                <path
                                    fill="#0c7238"
                                    d="M22.319,34H5.681C4.753,34,4,33.247,4,32.319V15.681C4,14.753,4.753,14,5.681,14h16.638 C23.247,14,24,14.753,24,15.681v16.638C24,33.247,23.247,34,22.319,34z"
                                />
                                <path
                                    fill="#fff"
                                    d="M9.807 19L12.193 19 14.129 22.754 16.175 19 18.404 19 15.333 24 18.474 29 16.123 29 14.013 25.07 11.912 29 9.526 29 12.719 23.982z"
                                />
                            </svg>
                        </div>
                    )}
                </div>
            </div>
            {isShowInput && chatGroup && (
                <form className="chat-app-form" onSubmit={handleSubmit(onSubmit)}>
                    <div className="input-group input-group-merge me-1 form-send-message">
                        <textarea
                            {...register('searchText')}
                            rows={1}
                            className="form-control message"
                            placeholder="Type your message"
                            onFocus={() => setIsFocused(true)}
                            onBlur={() => setIsFocused(false)}
                            onKeyDown={(e) => onEnter(e)}
                        />
                        <span className="input-group-text">
                            <label htmlFor="image-upload" className="attachment-icon form-label mb-0">
                                <Paperclip size={20} className="pointer" />
                                <input
                                    onChange={onChangeFile}
                                    type="file"
                                    id="image-upload"
                                    hidden
                                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel, image/*"
                                />{' '}
                            </label>
                        </span>
                    </div>
                    <div className="btn-send-group">
                        <button className="btn btn-primary send">
                            <Send size={14} className="d-lg-none" />
                            <span className="d-none d-lg-block">Send</span>
                        </button>
                        <div className="d-flex" style={{ width: 'max-content' }}>
                            <input
                                type="checkbox"
                                id="enterToSend"
                                name="enterToSend"
                                className="checkbox-btn"
                                checked={enterToSend}
                                onChange={onChangeSend}
                            />
                            <label htmlFor="enterToSend">Enter để gửi</label>
                        </div>
                    </div>
                </form>
            )}
            <ModalAddLinkChat
                productLink={linkOrder}
                show={show}
                changeShow={(s: boolean) => setShow(s)}
                submitAction={onSubmitAction}
            />
        </>
    );
}
