import User, { ChangePassword, LoginData, SearchUser } from 'types/User';
import { ApiResponse, DataListResponse } from 'types/common';
import http from './http';

const login = async (param: LoginData) => {
    const { data } = await http.post<ApiResponse<User>>('/user/login', param);
    return data;
};

const logout = async () => {
    const { data } = await http.get<ApiResponse<User>>('/user/logout');
    return data;
};

const forgotPassword = async (email: string) => {
    const { data } = await http.post<ApiResponse<{}>>('/user/forgotPassword', { email });
    return data;
};

const changePassword = async (param: ChangePassword) => {
    const { data } = await http.post<ApiResponse<{}>>('/user/changePassword', param);
    return data;
};

const register = async (param: Partial<User>) => {
    const { data } = await http.post<ApiResponse<User>>('/user/register', param);
    return data;
};

const getItem = async (id: number) => {
    const { data } = await http.get<ApiResponse<User>>(`/user/${id}`);
    return data;
};

const getProfile = async () => {
    const { data } = await http.get<ApiResponse<User>>('user/profile');
    return data;
};

const getList = async (param: SearchUser) => {
    const { data } = await http.post<ApiResponse<DataListResponse<User>>>('/user/list', param);
    return data;
};

const update = async (param: Partial<User>, id: number = 0) => {
    const { data } =
        id > 0
            ? await http.patch<ApiResponse<User>>(`/user/${id}`, param)
            : await http.post<ApiResponse<User>>('/user', param);
    return data;
};

const deleteItem = async (id: number) => {
    const { data } = await http.delete<ApiResponse<{}>>(`/user/${id}`);
    return data;
};

const getOnlineIds = async (ids: number[]) => {
    const { data } = await http.post<ApiResponse<User>>('user/online', { ids });
    return data;
};

const UserService = {
    login,
    logout,
    forgotPassword,
    changePassword,
    register,
    getItem,
    getProfile,
    getList,
    update,
    deleteItem,
    getOnlineIds,
};

export default UserService;
