import Action from 'types/Action';
import { ApiResponse, DataListResponse } from 'types/common';
import { HierarchyReturnType, ItemIds } from 'types/common/Item';
import http from './http';

const getList = async (type: HierarchyReturnType, roleId: number = 0) => {
    const { data } = await http.get<ApiResponse<DataListResponse<Action>>>(`/action/${type}/${roleId}`);
    return data;
};

const getIdsByUser = async (userId: number) => {
    const { data } = await http.get<ApiResponse<ItemIds>>('/action/user/' + userId);
    return data;
};

const update = async (param: Partial<Action>, id: number) => {
    if (id > 0) {
        const { data } = await http.put<ApiResponse<Action>>(`/action/${id}`, param);
        return data;
    } else {
        const { data } = await http.post<ApiResponse<Action>>('/action', param);
        return data;
    }
};

const deleteItem = async (id: number) => {
    const { data } = await http.delete<ApiResponse<{}>>(`/action/${id}`);
    return data;
};

const ActionService = {
    getList,
    getIdsByUser,
    update,
    deleteItem,
};

export default ActionService;
