import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import UpdateButton from 'components/partials/UpdateButton';
import { COMMON_MESSAGE } from 'constants/common';
import { useEffect, useLayoutEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Product from 'types/Product';
import { closeModal, toggleModalOpen } from 'utils/common';
import * as yup from 'yup';
import FormatNumber from '../FormatNumber';

interface IProps {
    productLink: string;
    show: boolean;
    changeShow: Function;
    submitAction: Function;
}

ModalAddLinkChat.defaultProps = {
    productLink: '',
    show: false,
    changeShow: null,
    submitAction: null,
};

const schema = yup
    .object({
        link: yup.string().required(COMMON_MESSAGE.FIELD_REQUIRED).trim(),
        quantity: yup.number().min(1, COMMON_MESSAGE.FIELD_REQUIRED),
    })
    .required();

export default function ModalAddLinkChat({ productLink, show, changeShow, submitAction }: IProps) {
    useLayoutEffect(() => toggleModalOpen(show), [show]);

    const [quantity, setQuantity] = useState(1);
    const [cost, setCost] = useState(0);

    const {
        register,
        setValue,
        setError,
        clearErrors,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<Product>({
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        reset({
            link: productLink,
            quantity: 1,
            reason: '',
            color: '',
            size: '',
            cost: 0,
            comment: '',
        });
    }, [productLink, show]);

    const onSubmit = (data: Product) => {};

    return (
        <>
            <div
                className={classNames('modal fade text-start modal-primary', { show })}
                style={{ display: show ? 'block' : 'none' }}
            >
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Thêm sản phẩm vào đơn hàng</h5>
                            <button type="button" className="btn-close" onClick={() => closeModal(changeShow)} />
                        </div>
                        <form className="validate-form pt-50" onSubmit={handleSubmit(onSubmit)}>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-12 mb-1">
                                        <label className="form-label">
                                            Link sản phẩm <span className="error">*</span>
                                        </label>
                                        <input
                                            {...register('link')}
                                            type="text"
                                            className={classNames('form-control', {
                                                'is-invalid': Boolean(errors.link?.message),
                                            })}
                                        />
                                        <span className="error">{errors.link?.message}</span>
                                    </div>
                                    <div className="col-12 col-sm-6 mb-1">
                                        <label className="form-label">Màu sắc</label>
                                        <input {...register('color')} type="text" className="form-control" />
                                    </div>
                                    <div className="col-12 col-sm-6 mb-1">
                                        <label className="form-label">Kích thước</label>
                                        <input {...register('size')} type="text" className="form-control" />
                                    </div>
                                    <div className="col-12 col-sm-6 mb-1">
                                        <label className="form-label">
                                            Số lượng <span className="error">*</span>
                                        </label>
                                        <FormatNumber
                                            value={quantity}
                                            isInput={true}
                                            onValueChange={(value: number) => {
                                                setQuantity(value);
                                                setValue('quantity', value);
                                                if (value > 0) clearErrors('quantity');
                                            }}
                                        />
                                        <span className="error">{errors.quantity?.message}</span>
                                    </div>
                                    <div className="col-12 col-sm-6 mb-1">
                                        <label className="form-label">Đơn giá(¥)</label>
                                        <FormatNumber
                                            value={cost}
                                            isInput={true}
                                            onValueChange={(value: number) => {
                                                setCost(value);
                                                setValue('cost', value);
                                            }}
                                        />
                                    </div>
                                    <div className="col-12 mb-1">
                                        <label className="form-label">Ghi chú</label>
                                        <input {...register('comment')} type="text" className="form-control" />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <UpdateButton isSubmitting={false} hasDivWrap={false} />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {show && <div className="modal-backdrop fade show" />}
        </>
    );
}
