import { ApiResponse } from 'types/common';
import { FileParam } from 'types/common/Item';
import file from './file';

const upload = async (fileObj: File, type: string = '') => {
    const formData = new FormData();
    formData.append('file', fileObj);
    const { data } = await file.post<ApiResponse<FileParam>>(
        type === '' ? '/file/upload' : `/file/upload?type=${type}`,
        formData
    );
    return data;
};

// const uploads = async (files: File[]) => {
//     const formData = new FormData();
//     files.forEach((fileObj: File) => formData.append('files', fileObj));
//     const { data } = await file.post<ApiResponse<FileParam[]>>('/file/uploads', formData);
//     return data;
// };

const FileService = {
    upload,
    //uploads,
};

export default FileService;
