import classNames from 'classnames';
import { optionSelectDefault } from 'constants/common';
import { useEffect, useState } from 'react';
import { X } from 'react-feather';
import Select, { SingleValue } from 'react-select';
import ChatService from 'services/ChatService';
import UserService from 'services/UserService';
import { ChatGroup } from 'types/Chat';
import User, { displayUserOnSelect, UserRole } from 'types/User';
import { ItemStatus, SelectOption, TrueFalse } from 'types/common/Item';
import './../../../components/partials/chat/app-chat-list.css';
import './../../../components/partials/chat/app-chat-panel.css';
import './../../../components/partials/chat/app-chat.css';
import ContentChatMessage from './ContentChatMessage';

interface IProps {
    show: boolean;
    profile: User | null;
    changeShow: () => void;
}

export default function ChatPanel({ show, profile, changeShow }: IProps) {
    const [optionUsers, setOptionUsers] = useState<SelectOption[]>([optionSelectDefault]);
    const [userValue, setUserValue] = useState<SelectOption>(optionSelectDefault);
    const [users, setUsers] = useState<User[]>([]);
    const [chatGroup, setChatGroup] = useState<ChatGroup>();

    useEffect(() => {
        const getUsers = async (isCustomer: boolean) => {
            const response = await UserService.getList({
                userStatusId: [ItemStatus.ACTIVE],
                roleId: isCustomer ? [UserRole.CUSTOMER_CARE_STAFF] : undefined,
                isCustomer: isCustomer ? undefined : TrueFalse.TRUE,
            });
            if (response.success) {
                setUsers(response.data.items);
                const options: SelectOption[] = [optionSelectDefault];
                response.data.items.forEach((c) => {
                    options.push({
                        value: c.id!,
                        label: displayUserOnSelect(c),
                    });
                });
                setOptionUsers(options);
            }
        };
        if (show && profile) {
            getUsers(profile.isCustomer === TrueFalse.TRUE);
        }
    }, [show, profile]);

    const getConversation = async (customerId: number, staffId: number) => {
        if (staffId === 0) staffId = profile?.id ?? 0;
        if (customerId > 0 && staffId > 0) {
            const response = await ChatService.getConversation(staffId, customerId);
            if (response.success) setChatGroup(response.data);
            else {
                setChatGroup({
                    conversationId: '',
                    staffId,
                    customerId,
                });
            }
        }
    };

    const onchangeUser = async (option: SingleValue<SelectOption>) => {
        setUserValue(option ?? optionSelectDefault);
        if ((option?.value ?? 0) > 0 && profile) {
            if (profile.isCustomer === TrueFalse.TRUE) getConversation(profile.id!, option?.value ?? 0);
            else getConversation(option?.value ?? 0, profile.id!);
        } else setChatGroup(undefined);
    };

    return (
        <div id="template-customizer" className={classNames({ 'template-customizer-open': show })}>
            <div className="card mb-0">
                <div className="card-header">
                    <h4 className="card-title">
                        {profile?.isCustomer === TrueFalse.TRUE
                            ? 'Chat với chăm sóc khách hàng'
                            : 'Chat với khách hàng'}
                    </h4>
                    <X className="cursor-pointer" size={14} onClick={changeShow} />
                </div>
                <div className="card-body">
                    <Select options={optionUsers} onChange={onchangeUser} value={userValue} />
                </div>
            </div>
            <div className="card chat-application">
                <section className="app-chat-wrapper">
                    <ContentChatMessage
                        isCustomer={profile?.isCustomer === TrueFalse.TRUE}
                        profileId={profile?.id ?? 0}
                        chatGroup={chatGroup}
                        users={users}
                        isFullWidth={true}
                        isShowInput={true}
                    />
                </section>
            </div>
        </div>
    );
}
