import classNames from 'classnames';

interface IProps {
    isSubmitting?: boolean;
    btnDisabled?: boolean;
    btnText?: string;
    btnClass?: string[];
    hasDivWrap?: boolean;
}

UpdateButton.defaultProps = {
    isSubmitting: false,
    btnDisabled: false,
    btnText: 'Cập nhật',
    btnClass: [],
    hasDivWrap: true,
};

export default function UpdateButton({ isSubmitting, btnDisabled, btnText, btnClass, hasDivWrap }: IProps) {
    return hasDivWrap ? (
        <div className="float-end">
            <button
                className={classNames('btn btn-primary waves-effect waves-float waves-light', btnClass)}
                disabled={btnDisabled}
            >
                {isSubmitting && <span className="spinner-border spinner-border-sm" />}
                <span className="ms-25 align-middle">{btnText ?? 'Update'}</span>
            </button>
        </div>
    ) : (
        <button
            className={classNames('btn btn-primary waves-effect waves-float waves-light', btnClass)}
            disabled={btnDisabled}
        >
            {isSubmitting && <span className="spinner-border spinner-border-sm" />}
            <span className="ms-25 align-middle">{btnText ?? 'Update'}</span>
        </button>
    );
}
