import { ArrowUp, Heart } from 'react-feather';

export default function Footer() {
    return (
        <>
            <div className="sidenav-overlay" />
            <div className="drag-target" />
            <footer className="footer footer-static footer-light">
                <p className="clearfix mb-0">
                    <span className="float-md-start d-block d-md-inline-block mt-25">
                        COPYRIGHT © 2023
                        <a className="ms-25" href="https://dathang86.vn" target="_blank">
                            Đặt hàng 86
                        </a>
                        <span className="d-none d-sm-inline-block">, All rights Reserved</span>
                    </span>
                    <span className="float-md-end d-none d-md-block">
                        Hand-crafted &amp; Made with <Heart />
                    </span>
                </p>
            </footer>
            <button className="btn btn-primary btn-icon scroll-top" type="button">
                <ArrowUp />
            </button>
        </>
    );
}
