import {
    Activity,
    Archive,
    Bell,
    CheckCircle,
    Circle,
    Code,
    Database,
    DollarSign,
    FastForward,
    Frown,
    List,
    MessageCircle,
    PhoneCall,
    PlusCircle,
    Settings,
    ShoppingBag,
    ShoppingCart,
    Truck,
    Type,
    UserCheck,
    Users,
    Youtube,
} from 'react-feather';

export const getIcon = (iconName: string | undefined): JSX.Element => {
    let element = <Circle size={14} />;
    switch (iconName) {
        case 'user-check':
            element = <UserCheck size={14} />;
            break;
        case 'bell':
            element = <Bell size={14} />;
            break;
        case 'dollar-sign':
            element = <DollarSign size={14} />;
            break;
        case 'shopping-cart':
            element = <ShoppingCart size={14} />;
            break;
        case 'shopping-bag':
            element = <ShoppingBag size={14} />;
            break;
        case 'plus-circle':
            element = <PlusCircle size={14} />;
            break;
        case 'frown':
            element = <Frown size={14} />;
            break;
        case 'truck':
            element = <Truck size={14} />;
            break;
        case 'fast-forward':
            element = <FastForward size={14} />;
            break;
        case 'archive':
            element = <Archive size={14} />;
            break;
        case 'phone-call':
            element = <PhoneCall size={14} />;
            break;
        case 'list':
            element = <List size={14} />;
            break;
        case 'users':
            element = <Users size={14} />;
            break;
        case 'activity':
            element = <Activity size={14} />;
            break;
        case 'message-circle':
            element = <MessageCircle size={14} />;
            break;
        case 'settings':
            element = <Settings size={14} />;
            break;
        case 'code':
            element = <Code size={14} />;
            break;
        case 'database':
            element = <Database size={14} />;
            break;
        case 'check-circle':
            element = <CheckCircle size={14} />;
            break;
        case 'type':
            element = <Type size={14} />;
            break;
        case 'youtube':
            element = <Youtube size={14} />;
            break;
        default:
            break;
    }
    return element;
};
