import { BaseModel, BaseSearch } from './common';
import { ItemParam, TrueFalse } from './common/Item';

export enum NotificationType {
    ORDER = 1,
    TRACKING = 2,
    TRANSPORT = 3,
    SHOP_TRACKING = 4,
    WITHDRAWAL = 5,
    COMPLAINT = 6,
    SHOP_COMMENT = 7,
    TRANSACTION = 8,
    SHOP = 9,
    PRODUCT = 10,
}

export const IsSeenNames: ItemParam[] = [
    { id: TrueFalse.TRUE, name: 'Đã đọc', className: 'badge badge-glow bg-success' },
    { id: TrueFalse.FALSE, name: 'Chưa đọc', className: 'badge badge-glow bg-danger' },
];
export interface SearchNotification extends BaseSearch {
    isSeen?: TrueFalse;
}
export default interface Notification extends BaseModel {
    title: string;
    itemId?: number;
    rootId?: number;
    notificationTypeId: NotificationType;
    userId: number;
    isSeen: TrueFalse;
}
