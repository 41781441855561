import { Conversation } from './Chat';
import { BaseModel, BaseSearch } from './common';
import { ItemParam, ItemStatus, TrueFalse } from './common/Item';

export enum Gender {
    MALE = 1,
    FEMALE = 2,
    OTHER = 3,
}

export interface LoginData {
    userName: string;
    userPass: string;
    isCustomer: number;
    isRemember: boolean;
    fcmToken: string;
}

export interface ChangePassword {
    userPass: string;
    reUserPass: string;
    token: string;
}

export enum UserRole {
    CUSTOMER_CARE_STAFF = 1,
    DELIVERY_STAFF = 2,
    GENERAL_MANAGER = 3,
    CUSTOMER = 4,
    ORDER_STAFF = 5,
    ACCOUNTING = 6,
    CUSTOMER_CARE_MANAGER = 7,
    ORDER_MANAGER = 8,
    DELIVERY_MANAGER = 9,
    MARKETING_STAFF = 10,
}

export interface SearchUser extends BaseSearch {
    id?: number[];
    userStatusId?: number[];
    genderId?: number[];
    roleId?: number[];
    isCustomer?: TrueFalse;
    isVip?: number;
    isPositiveBalance?: number;
}

export const GenderNames: ItemParam[] = [
    { id: Gender.MALE, name: 'Nam' },
    { id: Gender.FEMALE, name: 'Nữ' },
    { id: Gender.OTHER, name: 'Khác' },
];

export const displayUserOnSelect = (user: User | undefined, showPhone = true) => {
    if (user) {
        return showPhone ? user.name + ' - ' + user.phoneNumber : user.name;
    }
    return '';
};

export default interface User extends BaseModel {
    name: string;
    userPass: string;
    phoneNumber: string;
    email: string;
    address: string;
    genderId: Gender;
    userStatusId: ItemStatus;
    provinceId: number;
    avatar: string;
    comment: string;
    isCustomer: boolean | TrueFalse;
    roleId: UserRole;
    isVip: number;
    balance: number;
    careStaffId: number;
    orderAccountId?: number;
    insurancePercent: number;

    accessToken: string;
    refreshToken: string;

    reUserPass?: string;

    conversation?: Conversation;
}
