import axios from 'axios';
import { AUTH_KEYS } from 'constants/auth';
import { REACT_APP_API_URL } from 'constants/common';
import { getLocalStorage } from 'utils/localStorage';

const file = axios.create({
    baseURL: REACT_APP_API_URL,
    headers: {
        'Content-type': 'multipart/form-data',
        Authorization: `Bearer ${getLocalStorage(AUTH_KEYS.ACCESS_TOKEN) ?? ''}`,
    },
});

export default file;
