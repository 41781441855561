import { useAppDispatch } from 'app/hooks';
import classNames from 'classnames';
import { AUTH_KEYS } from 'constants/auth';
import { DEFAULT_VALUE, REACT_APP_SOCKET_URL } from 'constants/common';
import { UserRoleNames } from 'features/user/types';
import { filter, includes, isEmpty } from 'lodash';
import { useEffect, useLayoutEffect, useState } from 'react';
import { Bell, Circle, Disc, Home, Menu, MessageSquare, Moon, Power, Sun, User as UserX, X } from 'react-feather';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import ChatService from 'services/ChatService';
import NotificationService from 'services/NotificationService';
import { logout } from 'slices/authSlice';
import { io } from 'socket.io-client';
import Action from 'types/Action';
import Notification from 'types/Notification';
import User, { UserRole } from 'types/User';
import { TrueFalse } from 'types/common/Item';
import { getIcon } from 'utils/actionIcon';
import { getFieldInArrayObject, linkToNotification, showToast } from 'utils/common';
import { getLocalStorage, removeLocalStorage, setLocalStorage } from 'utils/localStorage';
import ChatPanel from './chat/ChatPanel';
interface IProps {
    listActions: Action[];
    user: User | null;
}

export default function Header({ listActions, user }: IProps) {
    const [darkMode, setDarkMode] = useState(false);
    const [showUser, setShowUser] = useState(false);
    const [showNotification, setShowNotification] = useState(false);
    const [showChat, setShowChat] = useState(false);
    const [chatUnreadCount, setChatUnreadCount] = useState(0);
    const [onScrollMenu, setOnScrollMenu] = useState(false);
    const [collapsedMenu, setCollapsedMenu] = useState(false);
    const [fixedMenu, setFixedMenu] = useState(!getLocalStorage('xproz_fixed_menu'));
    const [openActionIds, setOpenActionIds] = useState<number[]>([]);
    const [notifications, setNotifications] = useState<Notification[]>([]);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const sendUserStatus = () => {
        const socket = io(REACT_APP_SOCKET_URL, {
            withCredentials: true,
            extraHeaders: {
                Authorization: `Bearer ${getLocalStorage(AUTH_KEYS.ACCESS_TOKEN)}`,
            },
        });
        socket.on('connect', () => {
            socket.emit('msgToServer', {
                event: 'updateUserState',
                data: {
                    isOnline: true,
                },
            });
        });
        return () => {
            socket.disconnect();
        };
    };

    useEffect(() => {
        const getNotifications = async () => {
            if (user) {
                const { data } = await NotificationService.getList({ isSeen: TrueFalse.FALSE });
                setNotifications(data.items);
            }
        };
        const getChatUnreadCount = async () => {
            if (user) {
                const { data } = await ChatService.getTotalUnread(user.id!);
                setChatUnreadCount(data);
            }
        };
        getNotifications();
        getChatUnreadCount();

        // if (user) {
        //     const interval = setInterval(sendUserStatus, 5 * 60 * 1000); //5 * 60 * 1000
        //     return () => clearInterval(interval);
        // }
    }, [user]);

    useLayoutEffect(() => {
        if (user && user.isCustomer === TrueFalse.TRUE) {
            document.body.classList.add('customer-site');
        } else document.body.classList.remove('customer-site');
    }, [user]);

    useLayoutEffect(() => {
        document.body.classList.remove('blank-page');
        document.body.classList.add('menu-expanded');
    }, [listActions]);

    useLayoutEffect(() => {
        const menuElement = document.getElementById('main-menu-navigation');
        if (menuElement) {
            Array.from(menuElement.getElementsByClassName('nav-item')).forEach((element) => {
                Array.from(element.getElementsByTagName('a')).forEach((aEle) => {
                    if (aEle.classList.contains('active')) {
                        if (element.getElementsByClassName('menu-content').length > 0) {
                            aEle.closest('li')?.classList.add('active');
                            element.classList.add('sidebar-group-active');
                            element.classList.add('has-sub');
                            element.classList.add('open');
                        } else {
                            element.classList.add('active');
                        }
                        return false;
                    } else {
                        aEle.closest('li')?.classList.remove('active');
                    }
                });
            });
        }
    });

    useLayoutEffect(() => {
        if (darkMode) {
            document.body.classList.add('dark-layout');
        } else {
            document.body.classList.remove('dark-layout');
        }
    }, [darkMode]);

    useLayoutEffect(() => {
        if (fixedMenu) expandedMenuFn();
        else collapsedMenuFn();
        setLocalStorage('xproz_fixed_menu', fixedMenu ? '' : '1');
    }, [fixedMenu]);

    useLayoutEffect(() => {
        function changBodyClass() {
            if (window.innerWidth < 1200) {
                document.body.classList.add('vertical-overlay-menu');
                document.body.classList.add('menu-close');
                document.body.classList.remove('menu-collapsed');
                document.body.classList.remove('menu-expanded');
            } else {
                document.body.classList.remove('vertical-overlay-menu');
                document.body.classList.remove('menu-close');
            }
        }
        window.addEventListener('resize', changBodyClass);
        changBodyClass();
        return () => window.removeEventListener('resize', changBodyClass);
    }, []);

    const toggleMenuItem = (id: number) => {
        const items = [...openActionIds];
        if (includes(items, id)) {
            const index = items.indexOf(id);
            if (index > -1) items.splice(index, 1);
        } else items.push(id);
        setOpenActionIds(items);
    };

    const handleToggleMenu = () => {
        if (document.body.classList.contains('menu-close')) {
            document.body.classList.remove('menu-close');
            document.body.classList.add('menu-open');
            document.body.classList.remove('vertical-menu-modern');
        } else {
            document.body.classList.remove('menu-open');
            document.body.classList.add('menu-close');
            document.body.classList.add('vertical-menu-modern');
        }
    };

    const enterMenu = () => {
        if (!fixedMenu && window.innerWidth >= 1200) {
            expandedMenuFn();
        }
    };

    const leaveMenu = () => {
        if (!fixedMenu && window.innerWidth >= 1200) {
            collapsedMenuFn();
        }
    };

    const expandedMenuFn = () => {
        document.body.classList.remove('menu-collapsed');
        document.body.classList.add('menu-expanded');
        setCollapsedMenu(false);
    };

    const collapsedMenuFn = () => {
        document.body.classList.remove('menu-expanded');
        document.body.classList.add('menu-collapsed');
        setCollapsedMenu(true);
    };

    const readNotification = async (n: Notification) => {
        const response = await NotificationService.seen(n.id);
        if (response.success) {
            setShowNotification(false);
            setNotifications(filter([...notifications], (item) => item.id !== n.id));
        }
        const isCustomer = user && user.isCustomer === TrueFalse.TRUE;
        const url = linkToNotification(n.notificationTypeId, !!isCustomer, n.itemId ?? 0, n.rootId ?? 0);
        if (url) navigate(url);
    };

    const seenNotifications = async () => {
        const response = await NotificationService.seen();
        showToast(response.success, response.messages);
        if (response.success) {
            setShowNotification(false);
            setNotifications([]);
        }
    };

    const userLogout = async () => {
        const isCustomer = user && user.isCustomer === TrueFalse.TRUE;
        const logoutResult = await dispatch(logout());
        if (logout.fulfilled.match(logoutResult)) {
            if (logoutResult.payload.success) {
                removeLocalStorage(AUTH_KEYS.ACCESS_TOKEN);
                removeLocalStorage(AUTH_KEYS.REFRESH_TOKEN);
                navigate(isCustomer ? '/' : '/admin');
            }
        } else {
            showToast(false);
        }
    };

    return (
        <>
            <nav className="header-navbar navbar navbar-expand-lg align-items-center floating-nav navbar-light navbar-shadow container-xxl">
                <div className="navbar-container d-flex content">
                    <div className="bookmark-wrapper d-flex align-items-center">
                        <ul className="nav navbar-nav d-xl-none">
                            <li className="nav-item">
                                <a className="nav-link menu-toggle" onClick={handleToggleMenu}>
                                    <Menu size={14} />
                                </a>
                            </li>
                        </ul>
                    </div>
                    <ul className="nav navbar-nav align-items-center ms-auto">
                        {user &&
                            (user.isCustomer === TrueFalse.TRUE || user.roleId === UserRole.CUSTOMER_CARE_STAFF) && (
                                <li className="nav-item d-none d-lg-block">
                                    <a
                                        className="nav-link nav-link-style"
                                        onClick={() => setShowChat((prevShowChat) => !prevShowChat)}
                                    >
                                        <MessageSquare size={14} />
                                        {chatUnreadCount > 0 && (
                                            <span className="badge rounded-pill bg-danger badge-up">
                                                {chatUnreadCount}
                                            </span>
                                        )}
                                    </a>
                                </li>
                            )}
                        <li className="nav-item d-none d-lg-block">
                            <a
                                className="nav-link nav-link-style"
                                onClick={() => setDarkMode((prevDarkMode) => !prevDarkMode)}
                            >
                                {darkMode ? <Sun size={14} /> : <Moon size={14} />}
                            </a>
                        </li>
                        <li className="nav-item dropdown dropdown-notification me-25">
                            <a
                                className="nav-link"
                                onClick={() => {
                                    if (isEmpty(notifications)) {
                                        navigate(
                                            `${
                                                user && user.isCustomer === TrueFalse.TRUE ? '/customer' : ''
                                            }/notification`
                                        );
                                    } else setShowNotification((prevShow) => !prevShow);
                                }}
                            >
                                <Bell size={14} className="ficon" />
                                {!isEmpty(notifications) && (
                                    <span className="badge rounded-pill bg-danger badge-up">
                                        {notifications.length}
                                    </span>
                                )}
                            </a>
                            <ul
                                className={classNames('dropdown-menu dropdown-menu-media dropdown-menu-end', {
                                    show: showNotification,
                                })}
                            >
                                <li className="dropdown-menu-header">
                                    <div className="dropdown-header d-flex">
                                        <h4 className="notification-title mb-0 me-auto">
                                            <Link
                                                to={`${
                                                    user && user.isCustomer === TrueFalse.TRUE ? '/customer' : ''
                                                }/notification`}
                                            >
                                                Thông báo
                                            </Link>
                                        </h4>
                                        {!isEmpty(notifications) && (
                                            <div className="badge rounded-pill badge-light-primary">
                                                {notifications.length}
                                            </div>
                                        )}
                                    </div>
                                </li>
                                {!isEmpty(notifications) && (
                                    <>
                                        <li>
                                            <PerfectScrollbar className="scrollable-container media-list">
                                                {notifications.map((n: Notification) => (
                                                    <a
                                                        key={n.id}
                                                        className="d-flex"
                                                        onClick={() => readNotification(n)}
                                                    >
                                                        <div className="list-item d-flex align-items-start">
                                                            <div className="me-1">
                                                                <div className="avatar">
                                                                    <img
                                                                        src={DEFAULT_VALUE.IMAGE}
                                                                        alt="Đặt Hàng 86"
                                                                        width={32}
                                                                        height={32}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="list-item-body flex-grow-1">
                                                                <p className="media-heading">
                                                                    <span className="fw-bolder">Đặt Hàng 86</span>
                                                                </p>
                                                                <small className="notification-text">{n.title}</small>
                                                            </div>
                                                        </div>
                                                    </a>
                                                ))}
                                            </PerfectScrollbar>
                                        </li>
                                        <li className="dropdown-menu-footer">
                                            <a className="btn btn-primary w-100" onClick={seenNotifications}>
                                                Đánh dấu tất cả đã đọc
                                            </a>
                                        </li>
                                    </>
                                )}
                            </ul>
                        </li>
                        <li className="nav-item dropdown dropdown-user">
                            <a
                                className="nav-link dropdown-toggle dropdown-user-link"
                                onClick={() => setShowUser((prevShow) => !prevShow)}
                            >
                                <div className="user-nav d-sm-flex d-none">
                                    <span className="user-name fw-bolder">{user?.name}</span>
                                    <span className="user-status">
                                        {user?.isCustomer === TrueFalse.TRUE
                                            ? 'Khách hàng'
                                            : getFieldInArrayObject(UserRoleNames, user?.roleId ?? 0)}
                                    </span>
                                </div>
                                <span className="avatar">
                                    <img className="round" src={user?.avatar} alt="avatar" height={40} width={40} />
                                    <span className="avatar-status-online" />
                                </span>
                            </a>
                            <div className={classNames('dropdown-menu dropdown-menu-end', { show: showUser })}>
                                <Link
                                    to={
                                        user?.isCustomer === TrueFalse.TRUE
                                            ? '/customer/profile'
                                            : `/user/edit/${user?.id}`
                                    }
                                    className="dropdown-item"
                                    onClick={() => setShowUser((prevShow) => !prevShow)}
                                >
                                    <span className="me-50">
                                        <UserX size={14} />
                                    </span>{' '}
                                    Trang cá nhân
                                </Link>
                                <Link to="/" className="dropdown-item" onClick={userLogout}>
                                    <span className="me-50">
                                        <Power size={14} />
                                    </span>{' '}
                                    Đăng xuất
                                </Link>
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>
            <div
                className={classNames('main-menu menu-fixed menu-light menu-accordion menu-shadow', {
                    expanded: !collapsedMenu,
                })}
                onMouseEnter={enterMenu}
                onMouseLeave={leaveMenu}
            >
                <div className="navbar-header">
                    <ul className="nav navbar-nav flex-row">
                        <li className="nav-item me-auto">
                            <a className="navbar-brand" target="_blank" href="https://dathang86.vn">
                                <span className="brand-logo">
                                    <Home height={14} width={35} />
                                </span>
                                <h2 className="brand-text">ĐH86</h2>
                            </a>
                        </li>
                        <li className="nav-item nav-toggle">
                            <a className="nav-link modern-nav-toggle pe-0">
                                <span
                                    className="d-block d-xl-none text-primary toggle-icon font-medium-4"
                                    onClick={handleToggleMenu}
                                >
                                    <X size={14} />
                                </span>
                                <span
                                    className="d-none d-xl-block collapse-toggle-icon font-medium-4 text-primary"
                                    onClick={() => setFixedMenu((prevFixedMenu) => !prevFixedMenu)}
                                >
                                    {!fixedMenu && (
                                        <span>
                                            <Circle size={14} />
                                        </span>
                                    )}
                                    {fixedMenu && (
                                        <span>
                                            <Disc size={14} />
                                        </span>
                                    )}
                                </span>
                            </a>
                        </li>
                    </ul>
                </div>
                <div className={classNames('shadow-bottom', { onScrollMenu })} />
                <PerfectScrollbar
                    className="main-menu-content"
                    onScrollY={() => setOnScrollMenu((prevOnScrollMenu) => !prevOnScrollMenu)}
                >
                    <ul className="navigation navigation-main" id="main-menu-navigation" data-menu="menu-navigation">
                        <li className="nav-item">
                            {user?.isCustomer === TrueFalse.TRUE && (
                                <a href="https://dathang86.vn" className="d-flex align-items-center">
                                    <Home size={14} />
                                    <span className="menu-title text-truncate">Trang chủ</span>
                                </a>
                            )}
                            {user?.isCustomer !== TrueFalse.TRUE && (
                                <NavLink to="/dashboard" className="d-flex align-items-center">
                                    <Home size={14} />
                                    <span className="menu-title text-truncate">Dashboards</span>
                                </NavLink>
                            )}
                        </li>
                        {listActions?.map((action) => (
                            <li
                                className={classNames('nav-item', {
                                    'has-sub': !isEmpty(action.children),
                                    open: !isEmpty(action.children) && includes(openActionIds, action.id),
                                })}
                                key={action.id}
                                onClick={() => toggleMenuItem(action.id!)}
                            >
                                {!isEmpty(action.children) && (
                                    <>
                                        <a className="d-flex align-items-center">
                                            {getIcon(action.icon)}
                                            <span className="menu-title text-truncate">{action.name}</span>
                                        </a>
                                        <ul className="menu-content">
                                            {action.children!.map((children) => (
                                                <li key={children.id}>
                                                    <NavLink to={children.url} className="d-flex align-items-center">
                                                        {getIcon(children.icon)}
                                                        <span className="menu-title text-truncate">
                                                            {children.name}
                                                        </span>
                                                    </NavLink>
                                                </li>
                                            ))}
                                        </ul>
                                    </>
                                )}
                                {(!action.children || action.children.length === 0) && (
                                    <NavLink to={action.url} className="d-flex align-items-center">
                                        {getIcon(action.icon)}
                                        <span className="menu-title text-truncate">{action.name}</span>
                                    </NavLink>
                                )}
                            </li>
                        ))}
                    </ul>
                </PerfectScrollbar>
            </div>
            <ChatPanel show={showChat} profile={user} changeShow={() => setShowChat(false)} />
        </>
    );
}
