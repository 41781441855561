import NumberFormat from 'react-number-format';

interface IProps {
    value: string | number | null | undefined;
    isInput: boolean;
    inputClass?: string;
    inputDisabled?: boolean;
    onValueChange?: Function;
    onBlur?: Function;
    renderText?: (formattedValue: string) => React.ReactNode;
}

FormatNumber.defaultProps = {
    inputClass: '',
    inputDisabled: false,
    onValueChange: null,
    onBlur: null,
    renderText: null,
};

export default function FormatNumber({
    value,
    isInput,
    inputClass,
    inputDisabled,
    onValueChange,
    onBlur,
    renderText,
}: IProps) {
    return isInput ? (
        <NumberFormat
            className={`form-control${inputClass}`}
            value={value}
            thousandSeparator={','}
            decimalSeparator={'.'}
            onValueChange={(values) => {
                if (onValueChange) onValueChange(values.floatValue ?? 0);
            }}
            onBlur={(e: { target: { value: string | number } }) => {
                if (onBlur) onBlur(+e.target.value.toString().replace(/,/g, ''));
            }}
            disabled={inputDisabled}
        />
    ) : (
        <NumberFormat
            value={value}
            thousandSeparator={','}
            decimalSeparator={'.'}
            displayType={'text'}
            renderText={renderText}
        />
    );
}
